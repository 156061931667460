.map-search-container {
  padding-left: 0;
  padding-right: 0;

  .search-catalog-header {
    .header-text {
      font-size: 70px;
      font-family: tandelle;
    }
  }
}

.search-container {
  max-width: 321px;
  .search-filter {
    background-color: #1f0000;
    display: flex;
    flex-direction: column;
    align-items: center;

    .mobile-filter-header-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 246px;
      color: #fcfbf7;
      font-size: 16px;
      font-weight: 500;
      margin: 27px 0 26px;

      button {
        width: unset;
        border: unset;
        height: unset;
        width: unset;
        margin-top: unset;
        margin-bottom: unset;
      }
    }
    .filter-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 17px;
      padding-bottom: 16px;

      .MuiFormControl-root {
        padding: unset;
        font-size: 17px;
        font-weight: 500;
      .MuiInputBase-root {
        padding: unset;

        .MuiOutlinedInput-notchedOutline {
          display: none;
        }

        #creator-autocomplete {
          width: 100%;          
          height: 53px;
          background-color: #fcfbf7;
          border: solid 2px #d9d3c3;          
          padding: 2px 5px;
          font-size: 17px;
          font-weight: 500;
          font-family: cora, sans-serif;
        }
        .MuiAutocomplete-endAdornment {
          top: 1px;

          .MuiButtonBase-root {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

      .filter-label {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
        color: #bfbaac;
      }

      .year-range-inputs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .text-input {
          width: 114px;
        }
      }

      .text-input {
        height: 53px;
        background-color: #fcfbf7;
        border: solid 2px #d9d3c3;
        width: 246px;
        font-size: 17px;
        font-weight: 500;
        padding: 2px 5px;
      }
      .react-dropdown-select {
        height: 55px;
        background-color: #fcfbf7;
        border: solid 2px #d9d3c3;
        width: 246px;
        font-size: 17px;
        font-weight: 500;
        &-dropdown {
          background-color: #fcfbf7;
          border: none;
        }
        &-item {
          border: none;
        }
      }
    }

    button {
      width: 246px;
      height: 55px;
      border: solid 2px #d9d3c3;
      background-color: #d9d3c3;

      margin-top: 16px;
      margin-bottom: 23px;

      font-size: 14px;
      font-weight: 500;
    }

    .clear-search {
      button {
        width: unset;
        height: unset;
        border: unset;
        background-color: unset;
        margin-top: 16px;
        margin-bottom: unset;
        color: #bfbaac;
        padding: unset;
        text-decoration: underline;
      }
    }
  }
}
.results-info {
  margin-bottom: 42px;
  margin-top: 42px;
}
.results-table {
  max-width: 795px;

  &table {
    border-collapse: collapse;
  }
  .result-image {
    height: 42px;
    width: 42px;
    background-size: contain;
    border-radius: 50%;
  }

  thead {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    color: #140407;
    background-color: #e6dfce;
    height: 40px;
    th:nth-child(1) {
      min-width: 68px;
      max-width: 68px;
    }
    th:nth-child(3) {
      min-width: 400px;
      padding-left: 10px;
    }
    th {
      padding: 4px 0 4px 0;
      font-weight: bold;
      min-width: 65px;
    }
  }
  tbody {
    tr {
      border-bottom: 1px #d9d3c3 solid;
      padding-bottom: 11px;
      margin-top: 4px;

      .result-data {
        height: 48px;
        padding: 22px 0 22px 0;
        font-size: 16px;
        line-height: 26px;
      }
      .result-title {
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }
  }
}
.results-table-mobile {
  max-width: 685px;

  &table {
    border-collapse: collapse;
  }
  .result-image {
    height: 42px;
    width: 42px;
    background-size: contain;
    border-radius: 50%;
  }

  thead {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    color: #140407;
    background-color: #e6dfce;
    th:nth-child(1) {
      min-width: 68px;
      max-width: 68px;
    }
    th {
      padding: 4px 0 4px 0;
      font-weight: 500;
      min-width: 65px;
    }
  }
  tbody {
    tr {
      border-bottom: 1px #d9d3c3 solid;
      padding-bottom: 11px;
      margin-top: 4px;
      .result-data {
        height: 48px;
        padding: 22px 0 22px 0;
        font-size: 16px;
        line-height: 26px;
      }
      .result-title {
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 72px;
  .pagination-text {
    color: #140407;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 48px;
  }
  .pagination-count {
    margin-left: 5px;
    color: #140407;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 48px;
  }
  .pagination-input {
    height: 40px;
    width: 40px;
    color: #140407;
    font-family: Cora;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    background-color: #f8f5eb;
    box-shadow: none;
    border: 1px #140407 solid;
    margin-right: 10px;
  }
  .pagination-button-active {
    color: #140407;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 48px;
    cursor: pointer;
  }
  .pagination-button-disabled {
    color: #bfbaac;
    font-family: Cora;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
  }
}

.receive-updates-container {
  background: #f5efe1;
  min-height: 439px;

  .container {
    height: 100%;

    .row {
      height: 100%;
      padding: 144px 0 168px;
      justify-content: space-between;
      
      .description-column {
        min-width: 655px;
        max-width: 655px;
        .header {
          font-size: 36px;
          font-weight: 500;
          line-height: 40px;
          margin-bottom: 12px;
        }
        .text {
          margin-top: 12px;
          font-size: 20px;
          line-height: 32px;
          font-weight: 400;
        }
      }

      .email-list-container {
        display: flex;
        flex-direction: column;

        form {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .input-container {
            padding-top: 2px;
            padding-bottom: 2px;

            .text-input {
              height: 53px;
              background-color: #fcfbf7;
              border: solid 2px #d9d3c3;
              width: 350px;
              font-size: 17px;
              font-weight: 300;
              padding: 7px 12px 9px;

              ::placeholder {
                color: #898080;
              }
            }
          }

          button {
            margin-top: 30px;
            width: 350px;
            height: 48px;
            background-color: #7F0100;
            border: none;
            color: #FCFBF7;
          }
        }
      }

      .form-column {
        min-width: 350px;
        max-width: 350px;
        padding-left: 0;
        padding-right: 0;
        .header {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          margin-bottom: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .map-search-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .search-container {
    max-width: unset;

    .clear-search {
      button {
        margin-top: unset !important;
      }
    }
  }
  
  .receive-updates-container {
    .description-column {
      min-width: unset !important;
    }

    .form-column {
      max-width: unset !important;
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  .results-table {
    max-width: unset;

    thead {
      th:nth-child(1) {
        // min-width: 68px;
        // max-width: 68px;
      }
      th:nth-child(3) {
        min-width: unset;
        padding-left: 10px;
      }
      th {
        // padding: 4px 0 4px 0;
        // font-weight: 500;
        // min-width: 65px;
      }
    }
  }
}

.location-card-row {
  display: flex;
  flex-wrap: wrap;

  .location-card {
    &:nth-child(4) {
      margin-right: 0;
    }
    background-color: #FCFBF7;
    margin-right: 20px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    height: 336px;
    width: 255px;

    img {
      height: 256px;
      width: 255px;
    }

    .location-label {
      color: #140407;
      display: flex;
      line-height: 24px;
      font-size: 20px;
      font-weight: 500;
      align-items: center;
      margin: 28px 20px;
    }
  }

}