.section-header {
  background-color: #e1dbc9;
  color: #130609;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: #140407;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 24px;
  margin-top: 24px;
}
.section-line-item {
  border-bottom: 1px #d2cdbc solid;
  padding-bottom: 10px;
  padding-top: 10px;
  .title {
    @media screen and (max-width: 992px) {
      text-align: left;
    }
    text-align: right;
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 18px;
  }
  .description {
    font-family: 'Merriweather Sans', sans-serif;
  }
}

.creator {
  display: flex;
  flex-direction: column;
}
.map-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.map-container {
  margin-top: 20px;
  margin-bottom: 68px;
  max-width: 90vw;
  .map-image-container {
    position: relative;
  }
  .cta-container {
    &:hover {
      opacity: 1;
    }
    &--interactive {
      backdrop-filter: blur(3px);
      opacity: 0;
      transition: opacity 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;
    }
    background-color: #f9f5ebab;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .cta-title {
      padding-top: 30px;
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
      text-align: center;
    }
    .cta-button {
      margin-top: 20px;
      background-color: #7f0100;
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 48px;
      margin-bottom: 6px;
      color: white;
      text-align: center;
    }
    .cta-text {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      color: #898080;
    }
    .cta-link {
      color: #140407;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
}

.map-image-container {
  height: 640px !important;
  background-color: #e6dfce;
  margin-bottom: 58px;
  #ToolbarDisplay0 {
    top: unset !important;
    bottom: 0 !important;
  }
}

.map-share-container {
  background-color: white;
  .header {
    background-color: #1f0000;
    color: white;
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    .image-faq-link {
      color: #140407;
      font-family: 'Merriweather Sans', sans-serif;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 34px;
      text-decoration: underline;
    }
    .btn-bookmark {
      background-color: #7f0100;
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 48px;
      margin-bottom: 6px;
      color: white;
      text-align: center;
    }
    .btn-download {
      background-color: white;
      width: 250px;
      font-size: 21px;
      font-weight: 500;
      padding-top: 5px;
      padding-bottom: 5px;
      letter-spacing: 0;
      line-height: 48px;
      border: 3px #7f0100 solid;
      color: #7f0100;
      text-align: center;
    }

    .cta-button {
      margin-top: 20px;
      background-color: #7f0100;
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 48px;
      margin-bottom: 6px;
      color: white;
      text-align: center;
    }

    .share-title {
      font-size: 18px;
      margin-top: 15px;
    }
    .share-icons-container {
      width: 216px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 12px;
    }
    .share-icon {
      height: 42px;
      width: 42px;
      background-color: #e6dfce;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
