.popup-modal {
  display: none;
  z-index: 1050;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  &__content {
    position: relative;
    background-color: white;
    border-radius: 6px;
    min-height: 50px;
    min-width: 50px;
    max-height: 100%;
    width: 640px;
    overflow-y: auto;
  }
  .close_btn {
    position: absolute;
    z-index: 100;
    top: 5px;
    right: 5px;
    background-color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    line-height: 1;
    border: none;
    padding: none;
    margin: none;
    transform: rotate(45deg);
  }
  &--open {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
