@import url("https://use.typekit.net/icx2loj.css");

.header-container {
  min-height: 80px;
  background-color: #1F0000;

  .container {
    display: flex;
    align-items: center;
    min-height: 80px;
    max-width: 1100px;

    &-mobile-nav {
      flex-direction: column;
    }

    .row {
      justify-content: space-between;
      width: 1100px;
      min-height: 80px;
      align-items: center;
    }
  }


  .logo-container {
    display: flex;
    
  }

  .mobile-links-container {
    display: flex;
    justify-content: flex-end;
  }

  button.font-awesome-icon-search {
    background: none;
    // margin-left: 17.75px;
    padding: 5px 15px;
    // border: solid #fff 1px;
    // border-radius: 5px;
    border: unset;
    min-width: none;

    .search-svg {
      font-weight: 900;
      color: blue;
    }

    #Icon_awesome-search {
      font-weight: bold;
    }
    img {
      height: 16px;
      width: 16px;
    }

    &:after {
      display: none;
    }

    &:active {
      border: solid #fff 1px;
    }

  }

  .clickable {
    cursor: pointer;
  }
  a, .header-link {
    color: #FCFBF7;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    padding: 10px 10px;
    position: relative;
    text-decoration: none;

    &.font-awesome-icon-search {

      margin-left: 17.75px;
      padding: 5px 15px;
      border: solid #fff 1px;
      border-radius: 5px;

      .search-svg {
        font-weight: 900;
        color: blue;
      }

      #Icon_awesome-search {
        font-weight: bold;
      }
      img {
        height: 16px;
        width: 16px;
      }

      &:after {
        display: none;
      }

      &:active {
        border: solid #fff 1px;
      }

    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      bottom: 0;
      height: 3px;
      width: 100%;
      left: 0;
      transition: 0.3s;
      transition-timing-function: cubic-bezier(.58, .3, .005, 1);
    }

    &:hover:after {
      background-color: #fff;
    }

    &.logo {
      padding: unset;
      margin-left: 0;
      margin-right: 20px;

      &:after {
        display: none;
      }
    }
  }


  .header-links-container, .header-signin-container {
    display: flex;
    align-items: center;
    padding-left: unset !important;
    padding-right: unset !important;
  }

  .header-signin-container {
    justify-content: flex-end;
    // margin-left: 202px;
  }
}

.eicon-menu-bar:before {
    content: '\e816';
  
}

@media screen and (max-width: 992px) {
  .header-container {
    min-height: 140px;

    .row {
      width: 100% !important;
    }

    .logo-container {
      min-height: 140px;
      display: flex;
      align-items: center;
    }

    .mobile-links-container {
      min-height: 140px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .mobile-menu {
      display: flex;
      flex-direction: column;
      a {
        color: #212529 !important;
        padding-left: unset !important;
      }
    }

    .mobile-links {
      width: calc(100% - 20px) !important;
      background-color: #FCFBF7;
      position: absolute;
      top: 140px;
      margin-bottom: 15px;
      z-index: 20000;
      overflow:hidden;
      transition:transform 0.3s ease-out; // note that we're transitioning transform, not height!
      height:auto;
      transform:scaleY(1); // implicit, but good to specify explicitly
      transform-origin:top; // keep the top of the element in the same place. this is optional.
      &.collapsed {
        transform:scaleY(0); // *squish*
      }
    }
  }
}