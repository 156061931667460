@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;500&display=swap');
@import url("https://use.typekit.net/icx2loj.css");

body, html, #root, .page-wrapper {
  margin: 0;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

}

.container {
  flex: 1;
  max-width: 1100px;
}

body {
  font-family: cora, sans-serif;
  background-color: #F8F5EB;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.button-primary {
  margin-top: 30px;
  width: 350px;
  height: 48px;
  background-color: #7F0100 !important;
  border: none;
  color: #FCFBF7;
  font-weight: 500;
  border-radius: unset !important;
}

