.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 88px;
  margin-bottom: 80px;
  min-height: 640px;
  .login-title {
    color: #140407;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 24px;
  }

  .checkbox-input-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 350px;
    margin-bottom: 7px;

    label {
      margin-bottom: unset;
      margin-left: 7px;
    }
  }
  .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    label {
      color: #382D2D;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.9px;
      line-height: 24px;
      margin-bottom: 0;
    }
    input {
      border: 1px solid #D9D3C3;
      border-radius: 2px 2px 0 0;
      background-color: #FCFBF7 !important;
      height: 48px;
      width: 350px;
      padding: 7px 32px 9px 12px;
    }
  }
  .login-button {
    height: 48px;
    width: 350px;
    background-color: #7F0100;
    color: #FCFBF7;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 16px;
  }
  .cta-text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    color: #898080;
  }
  .cta-link {
    color: #140407;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }

  a.reset-password {
    color: #7F0100;
    font-weight: 500;
  }
}

@media screen and (max-width: 992px) {
  .login-container {
    min-height: unset;
  }
}