.footer-container {
  background-color: #140407;
  position: relative;
  bottom: 0;
  margin-top: auto;
  width: 100%;
  .container,
  .footer-main-row {
    background-color: #140407;
  }
  .footer-links-container,
  .donate-container {
    margin-top: 64px;
  }
  .footer-links-container {
    ul {
      max-width: 265px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      li {
        padding: 13px 20px;
      }
    }
    a {
      color: #fcfbf7;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
      margin: 16px 0;
    }
  }

  .terms-container {
    margin-top: 113px;
    background-color: #140407;
    a,
    span {
      color: #fcfbf7;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      margin-right: 30px;
    }
  }

  .donate-container {
    background-color: #140407;
    color: #fcfbf7;
    font-size: 21px;
    letter-spacing: 0;
    line-height: 32px;

    a {
      color: #fcfbf7;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 48px;
      border: 1px solid #cfb582;
      padding: 10px 20px 10px 20px;
      margin-right: 10px;
    }
  }

  .all-rights-reserved {
    color: #fcfbf7;
    width: 100%;
    padding: 10px 20px 30px 10px;
  }
}
@media screen and (max-width: 992px) {
  .footer-container {
    ul {
      display: flex;

      li {
        padding: 0 7.5px !important;
      }
    }
  }

  .receive-updates-container {
    min-height: unset !important;
    padding-top: 50px;
    padding-bottom: 50px;
    .receive-updates {
      min-height: unset !important;
      .email-list-row {
        flex-direction: column !important;
        padding: unset !important;
      }
    }
  }

  .terms-container {
    display: none;
  }

  .donate-container {
    padding: 25px 0;
  }
}

@media screen and (max-width: 576px) {
  .donate-container {
    padding-left: 5px;
    padding-right: 5px;
    max-width: unset !important;
    font-size: 16px !important;

    a {
      margin-right: 40px !important;
    }
  }

  .footer-links-container {
    a {
      font-size: 18px !important;
    }
  }

  .form-column {
    button {
      width: 100% !important;
    }
  }
}
