.search-bar-container {
  min-height: 76px;
  background-color: white;


  .container, .row {
    min-height: 76px;
  }

  .search-input {
    height: 32px;
    border: none;
    width: 100%;
    padding: 0 25.33px;

    &::placeholder {
      color: #B7B1B0;
    }
  }

  .search-by-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    color: #382D2D;
    border-left: 1px #D9D3C3 solid;
    border-right: 1px #D9D3C3 solid;
    height: 100%;
    min-width: 174.8px;
  }

  .search-input-container {
    display: flex;
    align-items: center;
    padding-left: unset;
    padding-right: unset;
    input:focus-visible {
      outline: unset;
    }

    input {
      color: #898080;
    }
  }

  .search-by-container {
    display: flex;
    align-items: center;
  }

  .text-button {
    background: none;
    border: none;
    color: #382D2D;
    &:focus {
      outline: none;
      background: none;
    }
  }
}